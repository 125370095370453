<script setup>
import { ref, computed } from "vue";
import { getIconUrl } from "../shared/theme";
import { QrcodeStream } from "vue-qrcode-reader";

const emit = defineEmits([
  "codeReceived",
  "cameraPermissionDenied",
  "cameraPermissionGranted",
]);

const props = defineProps(["startOverlayAnimation", "texts"]);

var startShakeAnimation = ref(false);
defineExpose({ shake });

const qrComponentActive = ref(false);
const hasAskedForPermission = ref(false);
const deniedPermission = ref(undefined);
const ready = ref(false);
const pauseCamera = ref(false);
const showRequestUI = computed(() => {
  if (!hasAskedForPermission.value && !deniedPermission.value) {
    return true;
  } else if (hasAskedForPermission.value) {
    if (!deniedPermission.value) {
      //this means we may have gained permission in the past
      return false;
    } else {
      //this means we have asked for permission and didntget it
      //so don't ask again for now.
      //we might later add another condition to enable re-asking
      return false;
    }
  }
});

//restore permission state from earlier sessions
checkCameraPermission();
if (hasAskedForPermission.value === true && !deniedPermission.value)
  qrComponentActive.value = true;

function askCameraPermission() {
  qrComponentActive.value = true;
  hasAskedForPermission.value = true;
  localStorage.setItem("asked_camera_permission", "true");
}

function checkCameraPermission() {
  if (localStorage.getItem("asked_camera_permission") === "true")
    hasAskedForPermission.value = true;
  if (localStorage.getItem("denied_camera_access") === "true") {
    deniedPermission.value = true;
    emit("cameraPermissionDenied");
  }
}

function cameraOn(e) {
  //console.log("camera on", e);
  ready.value = true;
}

function error(e) {
  //console.log("error", e);
  if (e.name === "NotAllowedError") {
    deniedPermission.value = true;
    localStorage.setItem("denied_camera_access", "true");
    emit("cameraPermissionDenied");
  }
}

function onDetect(detectedCodes) {
  if (detectedCodes[0].format == "qr_code") {
    console.log(detectedCodes[0].rawValue);
    emit("codeReceived", detectedCodes[0].rawValue, "scanner");
  }
}

function shake() {
  startShakeAnimation.value = true;
}

addEventListener("animationend", (event) => {
  startShakeAnimation.value = false;
});

addEventListener("visibilitychange", (e) => {
  if (document.hidden == true) {
    pauseCamera.value = true;
  } else {
    pauseCamera.value = false;
  }
});
</script>

<template>
  <div class="qrScanner" :class="{ permissionDenied: deniedPermission }">
    <div class="top">
      <h1 class="input-h1">QR-Code Scanner</h1>
      <div class="permissionTexts">
        <p v-if="deniedPermission">{{ props.texts.noPermissionText }}</p>
        <p v-if="!hasAskedForPermission">{{ props.texts.promptText }}</p>
        <p v-if="ready">{{ props.texts.grantedText }}</p>
      </div>
    </div>

    <div
      class="videoFlex"
      :class="{ hidden: deniedPermission, animation: startShakeAnimation }"
    >
      <div v-if="qrComponentActive" class="videoContainer">
        <qrcode-stream
          class="video"
          :paused="pauseCamera"
          @detect="onDetect"
          @camera-on="cameraOn"
          @error="error"
        ></qrcode-stream>

        <div class="overlay" :class="{ active: props.startOverlayAnimation }">
          <img :src="getIconUrl('check')" />
        </div>
      </div>
      <div v-if="showRequestUI" class="requestUI" @click="askCameraPermission">
        <div class="uiContent">
          <!-- <button> -->
          <img :src="getIconUrl('camera')" />
          <!-- <p>Freigeben</p> -->
          <!-- </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.qrScanner {
  height: 100%;
  width: 100%;
  background-color: var(--color-scanner);
  color: white;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: var(--border-padding) var(--border-padding)
    calc(var(--border-padding) * 3) var(--border-padding);
  container-type: size;

  button {
    width: 80%;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .top {
    width: 100%;
    max-width: var(--max-content-width);
    flex: 1 0 0;
    display: flex;
    flex-direction: column;
    margin: 0 0 var(--border-padding) 0;
  }

  .bottom {
    width: 100%;
    flex: 1 0 0;
    margin: var(--border-padding) 0 0 0;
  }

  .permissionTexts {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .videoFlex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60cqh;
    height: 60cqh;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--color-interactive);
    border: solid;
    border-width: 4px;
    border-color: white;
    flex-shrink: 0;
    &.hidden {
      display: none;
    }

    .requestUI {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      align-items: center;
      padding: 1rem;

      .uiContent {
        position: relative;
        height: 4rem;
        width: 4rem;
        // width: 100%;
        border-radius: 3rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        background-color: var(--color-base);
        // padding: 0 0.5rem;
        // border-radius: var(--style-radius);

        // font-size: 1rem;
        // line-height: 1.7rem;
        // font-family: var(--font-text-bold);
        // text-transform: uppercase;
        // letter-spacing: 0.15rem;

        img {
          height: 80%;
          // margin: 0 1rem 0 0;
        }
      }
    }

    .videoContainer {
      position: relative;
      width: 100%;
      height: 100%;

      .overlay {
        opacity: 0;
        transform: scale(0.5);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        transition: opacity 0.3s, transform 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 50%;
          height: 50%;
        }

        &.active {
          opacity: 1;
          transform: scale(1);
        }
      }

      .video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
      }

      * {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

.animation {
  animation-name: horizontal-shaking;
  animation-duration: 0.5s;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
