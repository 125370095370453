<script setup>
const emit = defineEmits([
  "call-info",
  "onInfoClicked",
  "onLanguageClick",
  "toggleOverlay",
]);
const props = defineProps(["headline", "subheadline", "text", "visible"]);
import { useStore } from "../store";
import { computed } from "vue";
import { getIconUrl, getImageUrl } from "../shared/theme";

var store = useStore();

const firstTimerUserText = computed(() => {
  if (store.meta !== undefined && store.language !== undefined) {
    return store.meta.ui_texts.overlay_content.info_new.text[store.language];
  }
});

const buttonText = computed(() => {
  if (store.firsTimeUser) {
    return "OK";
    // return "Starte die App";
  }
});
</script>

<template>
  <div class="overlayContainer" :class="props.visible ? 'show' : 'hide'">
    <img class="background" :src="getImageUrl('overlayBackground')" />
    <div class="overlayContent">
      <div class="head">
        <h1 class="overlay-h1">{{ props.headline }}</h1>
        <h2 class="overlay-h2">{{ props.subheadline }}</h2>
      </div>
      <div class="textContainer">
        <p class="overlay-body" v-if="store.firsTimeUser">
          {{ firstTimerUserText }}
        </p>
        <p class="overlay-body" v-else>{{ props.text }}</p>
      </div>
    </div>
    <button v-if="store.firsTimeUser" @click="() => emit('toggleOverlay')">
      {{ buttonText }}
    </button>
    <button
      v-if="store.firsTimeUser !== true"
      @click="() => emit('toggleOverlay')"
    >
      OK
    </button>
  </div>
</template>

<style lang="scss" scoped>
// .backDrop {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   opacity: 0.5;
//   background-color: black;
// }

.overlayContainer {
  position: absolute;
  top: 0vw;
  left: 0px;
  z-index: 999999;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: var(--color-dark);
  transition: opacity 0.3s;

  &.show {
    opacity: 1;
    pointer-events: inherit;
  }

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
  }

  .overlayContent {
    position: relative;
    overflow: scroll !important;
    width: 100%;
    max-width: var(--max-content-width);
    padding: calc(1 * var(--border-padding));
    height: 100%;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: scroll;

    .head {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin: 0 0 2rem 0;
      padding: var(--border-padding) var(--border-padding) 0 var(--border-padding);
      text-align: center;

      .overlay-h1 {
        margin: 0 0 2rem 0;
      }

      img {
        width: 6%;
        border-radius: 50%;
      }
    }

    .textContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0 0 20vh 0;
      flex-direction: column;
    }
  }
  button {
      position: absolute;
      bottom: calc(2 * var(--border-padding));
      z-index: 999;
      border-radius: 3rem;
    }
}
</style>
