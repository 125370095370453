<script setup>
import { useStore } from "../store";
import { ref, defineProps, computed } from "vue";
import LandscapeOverlay from "./LandscapeOverlay.vue";

const store = useStore();
const iRef = ref(null);
const props = defineProps(["id"]);
const emit = defineEmits(["exit"]);

const getFinalEmbedData = computed(() => {
  return store.getFinalData(props.id, "embedcontents");
});

function injectIFrameAction() {
  iRef.value.contentWindow.exitFrame = () => {
    emit("exit");
  };
}
</script>

<template>
  <LandscapeOverlay></LandscapeOverlay>
  <div class="embed" v-if="getFinalEmbedData">
    <iframe
      width="100%"
      height="100%"
      :src="getFinalEmbedData.url"
      ref="iRef"
      :onload="injectIFrameAction"
    ></iframe>
  </div>
</template>

<style lang="scss" scoped>
.embed {
  width: 100%;
  height: 100svh;
  border-style: none;
  border: none;
}
iframe {
  width: 100%;
  height: 100%;
  border-style: none;
  border: none;
}
</style>
