<script setup>
import { useStore } from "../store";
const emit = defineEmits(["onLanguageClick", "clicked"]);
const props = defineProps(["appTitle", "headerButton"]);
const store = useStore();
</script>

<template>
  <div class="header">
    <div class="left">
      <img @click="() => emit('clicked')" :src="props.headerButton" />
    </div>
    <h1 class="header-h1 center">{{ props.appTitle }}</h1>
    <div class="right" @click="() => emit('onLanguageClick')">
      <p
        class="languageOption header-languageswitcher"
        :class="{ active: store.language === 'de' }"
      >
        DE
      </p>
      <p class="separator">|</p>
      <p
        class="languageOption header-languageswitcher"
        :class="{ active: store.language === 'en' }"
      >
        EN
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  width: 100%;
  background-color: var(--color-base);
  color: white;
  box-sizing: border-box;
  padding: var(--border-padding);

  .left {
    height: 100%;
    object-fit: contain;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      height: 1.6rem;
    }
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    margin: 0;
  }

  .right {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // max-width: 90px;

    .languageOption {
      opacity: 0.5;
      font-size: 0.8rem;
      margin: 0 0 0 0.25rem;
      &.active {
        font-size: 1rem;
        opacity: 1;
      }
    }
    .separator {
      margin: 0 0.25rem;
      display: none;
    }
  }
}
</style>
