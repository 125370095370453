<script setup>
import ImageComponent from "../components/ImageComponent.vue";
import Audioplayer from "../components/Audioplayer.vue";
import Videoplayer from "../components/Videoplayer.vue";
import { useStore } from "../store";
import { defineProps, computed } from "vue";

const store = useStore();
const props = defineProps(["id"]);

const finalData = computed(() => {
  //console.log(store.getFinalData(props.id, "fsarticles"));
  return store.getFinalData(props.id, "fsarticles");
});
</script>

<template>
  <div v-if="finalData" class="articlewrapper">
    <div v-for="item in finalData.content_list" :key="item.id">
      <h1 class="article-h1" v-if="item.type === 'headline'">{{ item.value }}</h1>
      <h2 class="article-h2" v-if="item.type === 'subheadline'">{{ item.value }}</h2>
      <p class="article-body" v-if="item.type === 'text'">{{ item.value }}</p>
      <p class="article-caption" v-if="item.type === 'caption'">{{ item.value }}</p>
      <p class="article-quote" v-if="item.type === 'blockquote'">{{ item.value }}</p>
      <ImageComponent
        v-if="item.type === 'image'"
        :data="item.value"
      ></ImageComponent>
      <Videoplayer
        v-if="item.type === 'video'"
        :data="item.value"
      ></Videoplayer>
      <Audioplayer
        v-if="item.type === 'audio'"
        :data="item.value"
      ></Audioplayer>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.articlewrapper {
  max-width: var(--max-content-width);
  padding: var(--border-padding);

  h1 {
    margin-bottom: 1rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  video {
    margin-bottom: 0.75rem;
  }

  audio {
    margin-bottom: 1rem;
  }

  p {
    margin-bottom: 1rem;
  }

  .article-caption {
    margin-top: -0.75rem;
  }
}
</style>
