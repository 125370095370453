<script setup>
const emit = defineEmits(["codeReceived"]);
import { getIconUrl } from "../shared/theme";
import VuePincodeOtp from "vue3-pincode-otp";
import { reactive, ref, defineExpose, computed } from "vue";
import { getConfig } from "../shared/theme";
const props = defineProps(["startAnimation", "text"]);
var startAnimation = ref(false);

var numberOfDigits = ref();
numberOfDigits.value = getConfig("numberpuncher_digits");

const pincode = reactive({
  value: "",
});

function shake() {
  startAnimation.value = true;
}

addEventListener("animationend", (event) => {
  startAnimation.value = false;
});

defineExpose({ shake });
</script>

<template>
  <div v-if="numberOfDigits !== undefined" class="numberpuncher">
    <div class="top">
      <h1 class="input-h1">{{ props.text.headline }}</h1>
      <p>{{ props.text.text }}</p>
    </div>
    <div class="inputWrapper" :class="{ animation: startAnimation }">
      <VuePincodeOtp v-model="pincode.value" :digits="numberOfDigits" />
      <button
        class="button"
        @click="emit('codeReceived', pincode.value, 'puncher')"
        :class="{ active: pincode.value.length === numberOfDigits }"
      >
        <img :src="getIconUrl('search')" />
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.numberpuncher {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--border-padding);

  .top {
    width: 100%;
    max-width: var(--max-content-width);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 0 1rem 0;
    text-align: center;
  }

  .inputWrapper {
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    max-width: 500px;
    container-type: inline-size;

    .button {
      position: relative;
      width: 15cqw !important;
      height: 15cqw !important;
      border-radius: 0.5rem;
      margin-left: 0.5rem;
      padding: 0rem !important;

      opacity: 0.5;
      background-color: var(--color-base);
      
      &.active {
        opacity: 1;
      }

      img {
        width: 1rem;
        object-fit: contain;
      }
    }

    div.vue-pincode-input-wrapper {
      *,
      input.vue-pincode-input.default {
        position: relative;
        width: 15cqw !important;
        height: 15cqw !important;
        border-radius: 0.5rem;
        font-family: var(--font-title);
      }
    }
  }
}

.animation {
  animation-name: horizontal-shaking;
  animation-duration: 0.5s;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(10px);
  }

  50% {
    transform: translateX(-10px);
  }

  75% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(0);
  }
}
</style>
